<template>
  <!-- 观看视频页面 -->
  <div>
    <div class="house-header grey-header fixed-header broadcast-header">
      <div class style="padding: 0 18px">
        <div class="house-nav">
          <router-link to="/">
            <img
              class="logo"
              src="@/assets/img/logowhite.png"
              style="vertical-align: middle"
            />
          </router-link>
          <div class="broadcurson">
            正在学习：{{ $store.state.sectionName }}
          </div>
          <div class="haslogin layui-breadcrumb">
            <router-link to="/center/mylesson">
              <img
                class="icon"
                src="@/assets/img/mylesson-icon.png"
                style="
                  width: 24px;
                  display: inline-block;
                  vertical-align: middle;
                  margin-top: 2px;
                "
              />
              <span style="font-size: 13px; margin-right: 20px">我的课程</span>
            </router-link>
            <router-link to="/center/imformation">
              <img class="user" :src="this.$store.state.userInfo.avatar" />
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <div id="broadcast" class="broadcast clearfix">
      <div
        id="video-con"
        class="video-con"
        :style="{
          'min-height': computedWindowHeight,
          'background-color': 'rgb(243, 245, 246)',
        }"
      >
        <div>
          <div id="polyvideo" class="video-body"></div>
        </div>
        <div class="analysis" v-if="solvingIdea || remarks || comment">
          <div class="analysis-box" v-if="solvingIdea">
            <span>思路点评</span>
            <p :class="analysisopen ? 'open' : ''">{{ solvingIdea }}</p>
            <!-- <img
                :class="analysisopen ? 'troute' : ''"
                class="icon"
                src="@/assets/img/isopen.png"
                @click="analysisopen = !analysisopen"
            />-->
          </div>
          <div
            class="analysis-box"
            v-if="comment"
            :style="{ marginBottom: remarks ? '15px' : '0' }"
          >
            <span class="v">高频分析</span>
            <p :class="viewopen ? 'open' : ''">{{ comment }}</p>
            <!-- <img
                :class="viewopen ? 'troute' : ''"
                class="icon"
                src="@/assets/img/isopen.png"
                @click="viewopen = !viewopen"
            />-->
          </div>
          <div class="analysis-box" style="margin: 0" v-if="remarks">
            <span class="r">备注修改</span>
            <p :class="viewopen ? 'open' : ''">{{ remarks }}</p>
            <!-- <img
                :class="viewopen ? 'troute' : ''"
                class="icon"
                src="@/assets/img/isopen.png"
                @click="viewopen = !viewopen"
            />-->
          </div>
        </div>
        <div class="video-else">
          <div class="tabs">
            <ul>
              <li
                v-for="(item, index) in tabs"
                :key="index"
                :class="index == TabActive ? 'active' : ''"
                @click="tabChange(index)"
              >
                {{ item.name }}
              </li>
            </ul>
          </div>
          <!--课程描述-->
          <div v-if="TabActive == 0" class="tabs-con">
            <div class="cursondis">
              <div class="tit">
                <img class="icon" src="@/assets/img/dis-icon.png" />课程描述
              </div>
              <div class="dis">
                <p>
                  本课程属于CSON（Cspiration），Cspiration 致力于在 CS
                  领域内帮助中国人找到工作，让更多海外国人受益
                </p>
                <p>讲师：Edward Shi</p>
                <!--                <p>3.入职BAT大厂数据岗都有哪些要求？</p>-->
              </div>
            </div>
          </div>
          <div v-show="TabActive == 1" class="tabs-con">
            <div class="zj-review">
              <div class="yours">
                <el-form label-position="top" label-width="80px">
                  <el-form-item v-show="!wantReplay" label="你的评论">
                    <el-input @focus="handleClick" ref="replay1"></el-input>
                  </el-form-item>
                  <div
                    id="myWangEditor"
                    style="margin-bottom: 20px; border-radius: 5px"
                    v-show="wantReplay"
                  >
                    <Toolbar
                      style="
                        border: 1px solid #dcdfe6;
                        border-bottom: none;
                        border-top-left-radius: 5px;
                        border-top-right-radius: 5px;
                      "
                      :editor="editorId"
                      :defaultConfig="toolbarConfig"
                      :mode="mode"
                    />

                    <!-- 编辑器 -->
                    <Editor
                      style="
                        height: 150px;
                        border: 1px solid #dcdfe6;
                        border-top: none;
                        border-bottom-left-radius: 5px;
                        border-bottom-right-radius: 5px;
                      "
                      :editor="editorId"
                      :defaultConfig="editorConfig"
                      :defaultContent="getDefaultContent"
                      :mode="mode"
                      @onChange="onChange"
                      @onCreated="onCreated"
                    />
                  </div>
                  <div style="text-align: right">
                    <el-button
                      @click="submitComment"
                      size="small"
                      type="primary"
                    >
                      提交评论
                    </el-button>
                  </div>
                </el-form>
              </div>
              <div class="review-con" v-if="mainviews.length">
                <div class="sum">全部评论（{{ mainviewsTotal }}）</div>
                <div class="list-con">
                  <div
                    v-for="(item, index) in mainviews"
                    :key="index"
                    class="list"
                    style="background: #fff"
                  >
                    <div class="mainview">
                      <div class="views">
                        <!-- <div class="more">
                          <el-dropdown>
                            <i class="el-icon-more"></i>
                            <el-dropdown-menu slot="dropdown">
                              <el-dropdown-item>举报</el-dropdown-item>
                            </el-dropdown-menu>
                          </el-dropdown>
                        </div>-->
                        <!--                                            <img class="logo" src="@/assets/img/user-logo.png">-->
                        <div class="logo-bg">
                          <img :src="item.userAvatar" alt="avatar" />
                          <!-- {{ item.userAvatar | formatNum }} -->
                        </div>
                        <h2>{{ item.username }}</h2>
                        <!-- <h3>{{ item.info }}</h3> -->
                        <div class="words" v-html="item.content"></div>
                        <div class="do">
                          <span>{{ item.commentTime }}</span>
                          <!-- <span v-if="!item.iszan" @click="parentzan(index)">
                            <img src="@/assets/img/isgood.png"/>
                            {{ item.goodnum }}
                          </span>-->
                          <!-- <span
                              v-if="item.iszan"
                              style="color: #fa6400"
                              @click="parentzan(index)"
                          >
                            <img src="@/assets/img/isgoodactive.png"/>
                            {{ item.goodnum + 1 }}
                          </span>-->
                          <!-- <span
                              v-if="!item.isnozan"
                              @click="parentnozan(index)"
                          >
                            <img src="@/assets/img/isnotgood.png"/>
                            {{ item.notgoodnum }}
                          </span>
                          <span
                              v-if="item.isnozan"
                              style="color: #fa6400"
                              @click="parentnozan(index)"
                          >
                            <img src="@/assets/img/isnotgoodactive.png"/>
                            {{ item.notgoodnum + 1 }}
                          </span>-->
                          <span class="replay" @click="replay(index)"
                            >评论</span
                          >
                        </div>
                        <div v-if="item.replay" class="replay-con">
                          <el-form
                            :model="replyForm"
                            label-position="top"
                            label-width="0"
                          >
                            <el-form-item label>
                              <el-input
                                v-model="item.replyValue"
                                type="textarea"
                              ></el-input>
                            </el-form-item>
                            <div style="text-align: right">
                              <el-button
                                @click="
                                  submitReplayComment(
                                    item.id,
                                    item.id,
                                    item.replyValue
                                  )
                                "
                                type="primary"
                                size="small"
                              >
                                发表评论
                              </el-button>
                            </div>
                          </el-form>
                        </div>
                      </div>
                    </div>
                    <div v-if="!item.isopen && item.replies" class="total">
                      共{{ item.replies.length }}条评论，
                      <span @click="replay(index)">点击查看</span>
                    </div>
                    <div class="childview">
                      <div :class="item.isopen ? 'open' : ''" class="child-con">
                        <div
                          v-for="(child, ii) in item.replies"
                          :key="ii"
                          class="views"
                        >
                          <div class="logo-bg">
                            <!-- {{ child.userAvatar | formatNum }} -->
                            <img :src="item.userAvatar" alt="avatar" />
                          </div>
                          <h2>
                            {{ child.username }}
                            <!-- <span class="replay">回复</span>
                            {{ child.parentId }} -->
                          </h2>
                          <!-- <h3>{{ child.info }}</h3> -->
                          <div class="words" v-html="child.content"></div>
                          <div class="do">
                            <span>{{ child.commentTime }}</span>
                            <!-- <span v-if="!child.iszan" @click="zan(index, ii)">
                              <img src="@/assets/img/isgood.png" />
                              {{ child.goodnum }}
                            </span>
                            <span
                              v-if="child.iszan"
                              style="color: #fa6400"
                              @click="zan(index, ii)"
                            >
                              <img src="@/assets/img/isgoodactive.png" />
                              {{ child.goodnum + 1 }}
                            </span>
                            <span
                              v-if="!child.isnozan"
                              @click="nozan(index, ii)"
                            >
                              <img src="@/assets/img/isnotgood.png" />
                              {{ child.notgoodnum }}
                            </span>
                            <span
                              v-if="child.isnozan"
                              style="color: #fa6400"
                              @click="nozan(index, ii)"
                            >
                              <img src="@/assets/img/isnotgoodactive.png" />
                              {{ child.notgoodnum + 1 }}
                            </span> -->
                            <!--                            <span class="replay" @click="childreplay(index, ii)">回复</span>-->
                          </div>
                          <div v-if="child.replay" class="replay-con">
                            <el-form
                              :model="replyForm2"
                              label-position="top"
                              label-width="0"
                            >
                              <el-form-item label>
                                <el-input
                                  v-model="replyForm2.replyValue"
                                  type="textarea"
                                ></el-input>
                              </el-form-item>
                              <div style="text-align: right">
                                <el-button
                                  @click="
                                    submitReplayComment(
                                      child.id,
                                      item.id,
                                      replyForm2.replyValue
                                    )
                                  "
                                >
                                  发表评论
                                </el-button>
                              </div>
                            </el-form>
                          </div>
                        </div>
                        <div v-if="item.isopen && item.replies" class="total">
                          共{{ item.replies.length }}条评论，
                          <span @click="replay(index)">点击收起</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-show="TabActive == 2" class="tabs-con">
            <div class="zj-review">
              <div class="yours">
                <el-form :model="form" label-position="top" label-width="80px">
                  <el-form-item v-show="!wantReplay2" label="你的评论">
                    <el-input
                      v-model="form.desc"
                      @focus="handleClick2"
                      ref="replay2"
                    ></el-input>
                  </el-form-item>
                  <div
                    id="myWangEditor2"
                    v-show="wantReplay2"
                    style="margin-bottom: 20px"
                  >
                    <Toolbar
                      style="
                        border: 1px solid #dcdfe6;
                        border-bottom: none;
                        border-top-left-radius: 5px;
                        border-top-right-radius: 5px;
                      "
                      :editor="editorId2"
                      :defaultConfig="toolbarConfig"
                      :mode="mode"
                    />

                    <!-- 编辑器 -->
                    <Editor
                      style="
                        height: 150px;
                        border: 1px solid #dcdfe6;
                        border-top: none;
                        border-bottom-left-radius: 5px;
                        border-bottom-right-radius: 5px;
                      "
                      :editor="editorId2"
                      :defaultConfig="editorConfig"
                      :defaultContent="getDefaultContent"
                      :mode="mode"
                      @onChange="onChange2"
                      @onCreated="onCreated2"
                    />
                  </div>
                  <div style="text-align: right">
                    <el-button
                      @click="submitComment2"
                      size="small"
                      type="primary"
                      >提交评论</el-button
                    >
                  </div>
                </el-form>
              </div>
              <div class="kc-review">
                <div
                  v-for="(item, index) in lists"
                  :key="index"
                  class="list"
                  style="background: #fff"
                >
                  <!-- <div class="title">{{ item.title }}</div> -->
                  <div class="dis" v-html="item.content"></div>
                  <!-- <div class="include">
                    包含：
                    <span
                        v-for="(child, index) in item.includes"
                        :key="index"
                    >{{ child.name }}</span
                    >
                  </div>-->
                  <div class="do">
                    <p>
                      <img class="logo" :src="item.userAvatar" />
                      {{ item.username }}
                    </p>
                    <span>{{ item.commentTime }}</span>
                    <!-- <span v-if="!item.iszan" @click="listszan(index)">
                      <img src="@/assets/img/isgood.png"/>
                      {{ item.goodnum }}
                    </span>-->
                    <!-- <span
                        v-if="item.iszan"
                        style="color: #fa6400"
                        @click="listszan(index)"
                    >
                      <img src="@/assets/img/isgoodactive.png"/>
                      {{ item.goodnum + 1 }}
                    </span>-->
                    <!-- <span v-if="!item.isnozan" @click="listsnozan(index)">
                      <img src="@/assets/img/isnotgood.png"/>
                      {{ item.notgoodnum }}
                    </span>-->
                    <!-- <span
                        v-if="item.isnozan"
                        style="color: #fa6400"
                        @click="listsnozan(index)"
                    >
                      <img src="@/assets/img/isnotgoodactive.png"/>
                      {{ item.notgoodnum + 1 }}
                    </span>-->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="resize">||</div>
      <div id="code-con" class="code-con">
        <div class="code-tabs nav_title" id="nav_title">
          <ul class="nav_title_wrapper">
            <li
              :class="!codeShow ? 'active' : ''"
              :style="{ width: haveCode ? '50%' : '100%' }"
              style="border-right: 1px solid #f0f0f0"
              @click="getAudition()"
            >
              - 课程章节 -
            </li>
            <li v-if="haveCode" :class="codeShow ? 'active' : ''" class="lee">
              代码
              <i class="el-icon-caret-bottom"></i>
              <ul>
                <li
                  :class="activeCode == 'Java' ? 'active' : ''"
                  @click="switchCodeStyle('Java')"
                >
                  Java
                </li>
                <li
                  :class="activeCode == 'CPlusCode' ? 'active' : ''"
                  @click="switchCodeStyle('CPlusCode')"
                >
                  C++
                </li>
                <li
                  :class="activeCode == 'Python' ? 'active' : ''"
                  @click="switchCodeStyle('Python')"
                >
                  Python
                </li>
              </ul>
            </li>
          </ul>
        </div>
        <div v-show="!codeShow" class="tab-show">
          <div class="test-con pt40">
            <div id="test-ser-wrapper">
              <div class="test-ser">
                <img class="icon" src="@/assets/img/search-icon.png" />
                <input
                  v-model="searchKeyword"
                  placeholder="搜索章节标题"
                  @keyup.enter="searchEnterFun"
                />
              </div>
            </div>
            <div v-if="testList.length > 0" class="test-list">
              <div v-for="(item, index) in testList" :key="index" class="list">
                <div class="stop" @click="sopen(index)">
                  <span>
                    {{ item.chapterName }}
                  </span>
                  <img
                    :class="item.isOpen ? '' : 'not'"
                    class="icon"
                    src="@/assets/img/test-open-icon.png"
                  />
                </div>
                <div v-if="item.isOpen" class="child">
                  <div
                    v-for="(lis, jj) in item.section"
                    :key="jj"
                    :class="{
                      isEndClass: lis.sectionStudyStatus.value === 2,
                      // active: lis.active,
                      active: lis.lastWatch,
                    }"
                    class="schild"
                    @click="play(index, jj, item, lis)"
                  >
                    <span :title="lis.name" class="name">{{ lis.name }}</span>
                    <span v-if="lis.video">{{ lis.duration }}</span>
                    <!--                                        <img v-if="lis.active" src="@/assets/img/isplay-icon.png"/>-->
                  </div>
                </div>
              </div>
            </div>
            <div v-else style="text-align: center; margin-top: 100px">
              暂无结果，请清空筛选重新搜索
            </div>
          </div>
        </div>
        <div v-if="haveCode" v-show="codeShow" class="tab-show code-show">
          <div id="codeView" v-highlight>
            <pre>
              <code class="cpp" style="transform: translateY(-15px);" v-text="code"></code>
            </pre>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { videoPlayer } from "vue-video-player";
import "video.js/dist/video-js.css";
import footerLayout from "@/views/layout/children/footer.vue";
import { mapState } from "vuex";
import {
  getCodeByUrl,
  getVideoUrl,
  listAudition,
  startEnd,
  sectionList,
  comment,
  courseCommentList,
  coursePlanList,
  getLastWatchByGet,
  getWebSocketURL,
} from "@/service/index";

import "@wangeditor/editor/dist/css/style.css";
import { Editor, Toolbar } from "@wangeditor/editor-for-vue";
import cloneDeep from "lodash.clonedeep";
import Prism from "prismjs";
export default {
  components: {
    videoPlayer,
    footerLayout,
    Editor,
    Toolbar,
  },
  data() {
    return {
      testList: [],
      content: "",
      content2: "",
      // 保存一级二级回复值
      replyForm: {
        replyValue: "",
      },
      replyForm2: {
        replyValue: "",
      },
      myWangEditor: null,
      myWangEditor2: null,
      // editorOption: {},
      wantReplay: false,
      wantReplay2: false,
      analysisopen: false,
      viewopen: false,
      searchKeyword: "",
      solvingIdea: "",
      remarks: "",
      comment: "",
      haveCode: false,
      tabs: [
        {
          name: "课程描述",
        },
        {
          name: "章节评论",
        },
        // {
        //   name: "课程评价",
        // },
      ],
      TabActive: 0,
      codeShow: false,
      form: {
        desc: "",
      },
      mainviewsTotal: 0,
      mainviews: [],
      lists: [],
      vodPlayerJs:
        "https://player.polyv.net/resp/vod-player-drm/canary/player.js",
      vid: "",
      player: null,
      code: "",
      activeCode: "java",
      playItem: {},
      socketPath: "",
      socket: "",
      lockReconnect: false, //是否真正建立连接
      timeout: 1000, //30秒一次心跳
      timeoutObj: null, //心跳心跳倒计时
      serverTimeoutObj: null, //心跳倒计时
      timeoutnum: null, //断开 重连倒计时
      lastTime: 0, //默认上一次点击时间为0

      editorId: null,
      editorId2: null,
      toolbarConfig: {
        toolbarKeys: [
          "codeBlock",
          "|",
          "bold",
          "bulletedList",
          "numberedList",
          {
            key: "group-justify",
            title: "对齐",
            iconSvg:
              '<svg viewBox="0 0 1024 1024"><path d="M768 793.6v102.4H51.2v-102.4h716.8z m204.8-230.4v102.4H51.2v-102.4h921.6z m-204.8-230.4v102.4H51.2v-102.4h716.8zM972.8 102.4v102.4H51.2V102.4h921.6z"></path></svg>',
            menuKeys: [
              "justifyLeft",
              "justifyRight",
              "justifyCenter",
              "justifyJustify",
            ],
          },
          {
            key: "group-indent",
            title: "缩进",
            iconSvg:
              '<svg viewBox="0 0 1024 1024"><path d="M0 64h1024v128H0z m384 192h640v128H384z m0 192h640v128H384z m0 192h640v128H384zM0 832h1024v128H0z m0-128V320l256 192z"></path></svg>',
            menuKeys: ["indent", "delIndent"],
          },
          "|",
          "emotion",
          {
            key: "group-image",
            title: "图片",
            iconSvg:
              '<svg viewBox="0 0 1024 1024"><path d="M959.877 128l0.123 0.123v767.775l-0.123 0.122H64.102l-0.122-0.122V128.123l0.122-0.123h895.775zM960 64H64C28.795 64 0 92.795 0 128v768c0 35.205 28.795 64 64 64h896c35.205 0 64-28.795 64-64V128c0-35.205-28.795-64-64-64zM832 288.01c0 53.023-42.988 96.01-96.01 96.01s-96.01-42.987-96.01-96.01S682.967 192 735.99 192 832 234.988 832 288.01zM896 832H128V704l224.01-384 256 320h64l224.01-192z"></path></svg>',
            menuKeys: ["insertImage", "uploadImage"],
          },
          "|",
          "redo",
          "undo",
          "|",
          "fullScreen",
        ],
      },
      defaultContent: [],
      editorConfig: {
        placeholder: "请输入内容...",
        MENU_CONF: {
          uploadImage: {
            fieldName: "file",
            server:
              process.env.VUE_APP_BASE_URL + "/common/upload?isPublic=true",
            customInsert(res, insertFn) {
              insertFn(res.result.accessibleUrl);
            },
            headers: {
              "X-Access-Token": window.localStorage.getItem("CSON_PAGE_TOKEN"),
            },
            maxFileSize: 5 * 1024 * 1024,
          },
          codeSelectLang: {
            codeLangs: [
              { text: "Javascript", value: "javascript" },
              { text: "Java", value: "java" },
              { text: "Python", value: "python" },
              { text: "PHP", value: "php" },
              { text: "C++", value: "cpp" },
              { text: "C", value: "c" },
            ],
          },
        },
      },
      mode: "default",
      curContent: [],
      curContent2: [],
    };
  },
  filters: {
    formatNum: function (value) {
      if (!value) return "";
      return value.substring(0, 2);
    },
  },
  created() {},
  mounted() {
    this.$nextTick(() => {
      // this.loadPlayerScript(() => {
      //   this.vId = "5c1d111f6370b4e9609aed84576c7cb7_5";
      //   this.loadPlayer();
      // });
      // 获取最后一次观看章节信息
      this.getLastWatch();
      // 获取课程章节列表
      this.getAudition();
      //获取课程评价
      this.getKCCommentList();
      var resize = document.getElementById("resize");
      resize.addEventListener("mousedown", this.handleResize);
    });
  },
  watch: {
    userInfo: {
      deep: true,
      handler(val) {
        if (this.userInfo.id) {
          getWebSocketURL().then((res) => {
            this.socketPath = res.result;
            this.socketInit();
          });
        }
      },
    },
  },
  computed: {
    computedWindowHeight() {
      return window.innerHeight - 54 + "px";
    },
    ...mapState(["userInfo"]),
    getDefaultContent() {
      return cloneDeep(this.defaultContent);
    },
  },
  methods: {
    socketInit() {
      if (typeof WebSocket === "undefined") {
        alert("您的浏览器不支持socket");
      } else {
        // 实例化socket
        // console.log(this.socketPath)
        this.socket = new WebSocket(this.socketPath);
        // 监听socket连接
        this.socket.onopen = this.socketOpen;
        // 监听socket错误信息
        // this.socket.onerror = this.error
        // 监听socket消息
        this.socket.onmessage = this.getMessage;
      }
    },
    getMessage(msg) {
      this.reset();
      console.log(msg.data);
      if (msg.data && !JSON.parse(msg.data).resultMessage) {
        window.localStorage.setItem("CSON_PAGE_TOKEN", "");
        window.location.href = "#/";
        window.location.reload();
      }
    },
    throttle() {
      let now = new Date().valueOf();
      if (now - this.lastTime > 1500) {
        this.lastTime = now;
        return true;
      } else {
        return false;
      }
    },
    socketOpen() {
      this.start();
      this.socket.send(
        JSON.stringify({
          method: 0,
          message: {
            userId: this.userInfo.id,
            token: localStorage.getItem("CSON_PAGE_TOKEN"),
          },
        })
      );
    },
    reconnect() {
      //重新连接
      var that = this;
      if (that.lockReconnect) {
        return;
      }
      that.lockReconnect = true;
      //没连接上会一直重连，设置延迟避免请求过多
      that.timeoutnum && clearTimeout(that.timeoutnum);
      that.timeoutnum = setTimeout(function () {
        //新连接
        that.socketInit();
        that.lockReconnect = false;
      }, 1000);
    },
    reset() {
      //重置心跳
      //清除时间
      clearTimeout(this.timeoutObj);
      clearTimeout(this.serverTimeoutObj);
      //重启心跳
      this.start();
    },
    start() {
      //开启心跳
      var self = this;
      self.timeoutObj && clearTimeout(self.timeoutObj);
      self.serverTimeoutObj && clearTimeout(self.serverTimeoutObj);
      self.timeoutObj = setTimeout(function () {
        //这里发送一个心跳，后端收到后，返回一个心跳消息，
        if (self.socket.readyState == 1) {
          //如果连接正常
          self.socket.send(
            JSON.stringify({
              method: 0,
              message: {
                userId: self.$store.state.userInfo.id,
                token: localStorage.getItem("CSON_PAGE_TOKEN"),
              },
            })
          );
        } else {
          //否则重连
          self.reconnect();
        }
        self.serverTimeoutObj = setTimeout(function () {
          //超时关闭
          self.socket.close();
        }, self.timeout);
      }, self.timeout);
    },
    tabChange(index) {
      this.TabActive = index;
    },
    replay(index) {
      this.$set(this.mainviews[index], "replay", !this.mainviews[index].replay);
      this.$set(this.mainviews[index], "isopen", !this.mainviews[index].isopen);
    },
    childreplay(index, ii) {
      this.$set(
        this.mainviews[index].replies[ii],
        "replay",
        !this.mainviews[index].replies[ii].replay
      );
    },
    handleBlur() {
      if (!this.content) {
        this.wantReplay = false;
      }
    },
    handleBlur2() {
      if (!this.content2) {
        this.wantReplay2 = false;
      }
    },
    handleClick() {
      this.wantReplay = true;
      // 手动获取焦点
      this.$nextTick(() => {
        this.editorId.focus();
      });
    },
    onCreated(editor) {
      this.editorId = Object.seal(editor);
    },
    onCreated2(editor) {
      this.editorId2 = Object.seal(editor);
    },
    dataFormat(time) {
      return `${time.getFullYear()}-${
        time.getMonth() + 1 >= 10
          ? time.getMonth() + 1
          : "0" + (time.getMonth() + 1)
      }-${time.getDate() >= 10 ? time.getDate() : "0" + time.getDate()} ${
        time.getHours() >= 10 ? time.getHours() : "0" + time.getHours()
      }:${
        time.getMinutes() >= 10 ? time.getMinutes() : "0" + time.getMinutes()
      }:${
        time.getSeconds() >= 10 ? time.getSeconds() : "0" + time.getSeconds()
      }`;
    },
    handleClick2() {
      this.wantReplay2 = true;
      // 手动获取焦点
      this.$nextTick(() => {
        this.editorId2.focus();
      });
    },
    // 回复别人的评论
    submitReplayComment(pid = 0, topLevelId = 0, content) {
      comment({
        content,
        objectId: this.playItem.sectionId,
        pid,
        sectionResourceType: 0,
        topLevelId,
        // 评论类型：0、大课，1、小课、2、章节
        type: 2,
        visibility: true,
      }).then((res) => {
        // 成功则获取评论
        if (res.success) {
          this.$message.success("评论成功!");
          this.getZJCommentList();
        }
      });
    },
    onChange(editor) {
      this.curContent = editor.children;
      this.content = editor.getHtml();
    },
    // 默认顶层评论
    submitComment() {
      comment({
        content: this.content,
        objectId: this.playItem.sectionId,
        pid: 0,
        sectionResourceType: 0,
        topLevelId: 0,
        // 评论类型：0、大课，1、小课、2、章节
        type: 2,
        visibility: true,
      }).then((res) => {
        // 成功获取评论
        if (res.success) {
          this.$message.success("评论成功!");
          this.getZJCommentList();

          this.content = "";
          this.curContent = [];
          this.editorId.clear();
          this.$nextTick(() => {
            this.wantReplay = false;
          });
        }
      });
    },
    onChange2(editor) {
      this.curContent2 = editor.children;
      this.content2 = editor.getHtml();
    },
    // 课程评论
    submitComment2() {
      comment({
        content: this.content2,
        objectId: this.$route.query.courseId,
        // 暂时默认都只有一层评论
        pid: 0,
        sectionResourceType: 0,
        topLevelId: 0,
        type: this.$route.query.superType == 0 ? 3 : 1,
        // type: 0,
        visibility: true,
      }).then((res) => {
        // 成功获取评论
        if (res.success) {
          this.$message.success("评论成功!");
          this.getKCCommentList();
          this.content2 = "";
          this.curContent2 = [];

          this.editorId2.clear();
          this.$nextTick(() => {
            this.wantReplay2 = false;
          });
        }
      });
    },
    getZJCommentList() {
      sectionList(this.playItem.sectionId).then((res) => {
        if (res.success) {
          let newMainviews = res.result.records;
          // 如果数据相同则更新数据并继承原数据的打开评论状态
          if (this.mainviewsTotal === res.result.total) {
            this.mainviews = newMainviews.map((c, i) => {
              return {
                ...c,
                replay: this.mainviews[i].replay,
                isopen: this.mainviews[i].isopen,
              };
            });
          } else {
            this.mainviews = res.result.records;
          }
          this.mainviewsTotal = res.result.total;
          this.$nextTick(() => {
            Prism.highlightAll();
          });
        }
      });
    },
    getKCCommentList() {
      if (this.$route.query.superType == 0) {
        coursePlanList(this.$route.query.courseId).then((res) => {
          if (res.success) {
            this.lists = res.result.records;
            this.$nextTick(() => {
              Prism.highlightAll();
            });
          }
        });
      } else {
        courseCommentList(this.$route.query.courseId).then((res) => {
          if (res.success) {
            this.lists = res.result.records;
            this.$nextTick(() => {
              Prism.highlightAll();
            });
          }
        });
      }
    },
    searchEnterFun() {
      const { userCourseId, modelId } = this.$route.query;
      listAudition(
        userCourseId,
        this.searchKeyword,
        modelId ? modelId : ""
      ).then((res) => {
        if (res.success) {
          this.testList = res.result.chaptersVOS;
          this.haveCode = res.result.haveCode;
        }
      });
    },
    sopen(index) {
      this.testList[index].isOpen = !this.testList[index].isOpen;
    },
    play(index, jj, item, lis) {
      console.log(lis);
      if (!lis.video) {
        window.open(lis.videoUrl);
        return;
      }
      for (let i = 0; i < this.testList.length; i++) {
        for (let j = 0; j < this.testList[i].section.length; j++) {
          // this.testList[i].section[j].active = false;
          this.testList[i].section[j].lastWatch = false;
        }
      }
      // this.testList[index].section[jj].active = true;
      this.testList[index].section[jj].lastWatch = true;
      this.testList[index].section[jj].sectionStudyStatus.value = 2;
      // 课程名称更改显示
      this.$store.state.sectionName = this.testList[index].section[jj].name;
      getVideoUrl(this.testList[index].section[jj].videoUrl).then((res) => {
        // const polyvPlayer = window.polyvPlayer;
        // this.player = polyvPlayer({
        //     wrap: "#polyvideo",
        //     width: "100%",
        //     height: 600,
        //     vid: item.section[jj].videoUrl, //需要切换的视频vid
        //     playsafe: res.result.playsafe, //新获取的playsafe token
        //     sign: res.result.sign, //新获取的sign和ts参数
        //     ts: res.result.ts
        // });
        this.player.changeVid({
          vid: item.section[jj].videoUrl, //`需要切换的视频vid`
          playsafe: res.result.playsafe, //新获取的playsafe token
          sign: res.result.sign, //新获取的sign和ts参数
          ts: res.result.ts,
          code:
            this.userInfo.id +
            "-" +
            this.$route.query.courseId +
            "-" +
            this.$route.query.superType,
          forceH5: true,
          autoplay: true,
        });
        // this.vid = item.section[jj].videoUrl
      });
      this.playItem = item.section[jj];
      this.solvingIdea = item.section[jj].solvingIdea;
      this.remarks = item.section[jj].remarks;
      this.comment = item.section[jj].comment;
      if (this.throttle()) {
        startEnd({
          courseId:
            this.$route.query.superType == 0
              ? Number(this.$route.query.modelId)
              : Number(this.$route.query.courseId),
          resourceType: item.section[jj].sectionResourceType.value,
          sectionId: item.section[jj].sectionId,
          superType: Number(this.$route.query.superType),
          updateTime: this.dataFormat(new Date()),
        }).then((res) => {});
      }

      // sectionList(item.section[jj].sectionId).then((res) => {
      //   if (res.success) {
      //     this.mainviews = res.result.records;
      //     this.mainviewsTotal = res.result.total;
      //   }
      // });
      // courseCommentList(this.playItem.sectionId).then((res) => {
      //   if (res.success) {
      //     this.lists = res.result.records;
      //     // this.mainviewsTotal = res.result.total
      //   }
      // });
      this.getZJCommentList();
    },

    loadPlayerScript(callback) {
      if (!window.polyvPlayer) {
        const myScript = document.createElement("script");
        myScript.setAttribute("src", this.vodPlayerJs);
        myScript.onload = callback;
        document.body.appendChild(myScript);
      } else {
        callback();
      }
    },

    loadPlayer() {
      const polyvPlayer = window.polyvPlayer;
      // this.player = polyvPlayer({
      //   wrap: "#polyvideo",
      //   width: "100%",
      //   height: 600,
      //   vid: this.vId,
      //   forceH5: true,
      // });
      if (
        this.$route.query.courseId &&
        this.$route.query.superType &&
        this.userInfo.id
      ) {
        getVideoUrl(this.vId).then((res) => {
          if (res.success) {
            this.player = polyvPlayer({
              wrap: "#polyvideo",
              width: "100%",
              height: 600,
              vid: this.vId, //需要切换的视频vid
              playsafe: res.result.playsafe, //新获取的playsafe token
              sign: res.result.sign, //新获取的sign和ts参数
              ts: res.result.ts,
              code:
                this.userInfo.id +
                "-" +
                this.$route.query.courseId +
                "-" +
                this.$route.query.superType,
              forceH5: true,
              autoplay: true,
              viewerInfo: {
                viewerId: this.userInfo.id,
              },
            });
            // 视频播放完毕更新右侧章节列表状态
            this.player.on("s2j_onPlayOver", this.getAudition);
          }
        });
      } else {
        this.$message.error("参数错误");
      }
    },
    switchCodeStyle(language) {
      this.codeShow = true;
      this.activeCode = language;
      if (this.playItem.codeUrl) {
        const codeUrl = this.playItem.codeUrl[language];
        getCodeByUrl(codeUrl).then((res) => {
          if (res.success) {
            this.code = res.result;
            console.log(this.code);
          } else {
            this.code = "";
          }
        });
      }
    },
    getLastWatch() {
      const { superType, courseId, modelId } = this.$route.query;
      getLastWatchByGet(superType, superType == 0 ? modelId : courseId)
        .then((res) => {
          if (res.success) {
            this.playItem = res.result;
            this.solvingIdea = res.result.solvingIdea;
            this.remarks = res.result.remarks;
            this.comment = res.result.comment;
            this.getZJCommentList();
            this.$store.state.sectionName = res.result.name;
          }
        })
        .finally(() => {
          // 请求完成初始化编辑器如果没有初始化
          if (!this.player) {
            this.$nextTick(() => {
              this.loadPlayerScript(() => {
                // this.vId = "5c1d111f6370b4e9609aed84576c7cb7_5";
                if (this.playItem.videoUrl) {
                  this.vId = this.playItem.videoUrl;
                }
                this.loadPlayer();
              });
            });
          }
        });
    },
    getAudition() {
      this.codeShow = false;
      const { userCourseId, modelId } = this.$route.query;
      listAudition(
        userCourseId,
        this.searchKeyword,
        modelId ? modelId : ""
      ).then((res) => {
        if (res.success) {
          this.testList = res.result.chaptersVOS;
          this.haveCode = res.result.haveCode;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    handleResize(e) {
      // 获取需要修改的元素宽度
      var resize = document.getElementById("resize");
      var left = document.getElementById("video-con");
      var right = document.getElementById("code-con");
      var nav_title = document.getElementById("nav_title");
      var test_ser = document.getElementById("test-ser-wrapper");
      var box = document.getElementById("broadcast");

      var startX = e.clientX;
      resize.left = resize.offsetLeft;
      document.onmousemove = (e) => {
        var endX = e.clientX;

        var moveLen = resize.left + (endX - startX);
        var maxT = box.clientWidth - resize.offsetWidth;
        if (moveLen < 650) moveLen = 650;
        if (moveLen > maxT - 350) moveLen = maxT - 350;
        // resize.style.left = moveLen;
        resize.style.right = box.clientWidth - moveLen + "px";
        left.style.width = moveLen + "px";
        right.style.width = box.clientWidth - moveLen + "px";
        nav_title.style.width = box.clientWidth - moveLen - 10 + "px";
        test_ser.style.width = box.clientWidth - moveLen - 10 + "px";
      };
      document.onmouseup = (evt) => {
        document.onmousemove = null;
        document.onmouseup = null;
        resize.releaseCapture && resize.releaseCapture();
      };
      resize.setCapture && resize.setCapture();
      return false;
    },
  },
  beforeDestroy() {
    console.log("test");
    if (this.player) {
      this.player.off("s2j_onPlayOver", this.getAudition);
      this.player.destroy();
    }
    var resize = document.getElementById("resize");
    if (resize) {
      resize.removeEventListener("mousedown", this.handleResize);
    }
  },
};
</script>

<style lang="less" scoped>
.nav_title_wrapper {
  display: flex;
}
.nav_title_wrapper li {
  flex: 1;
}
.nav_title {
  width: 100%;
  right: 19px;
  top: 50px;
  z-index: 999;
  background-color: #fff;
}

.house-header .logo {
  margin-top: 0;
}

#codeView {
  height: 100%;

  pre {
    height: 100%;

    code {
      height: 100%;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }
  }
}

.broadcast {
  width: 100%;
  height: 100%;
  overflow: auto;
  display: flex;

  #video-con {
    width: calc(70% - 5px);
    height: 100%;
    overflow-y: auto;
  }

  .video-con .video-body {
    height: 600px;
    width: 100%;
    background: #00101b;
  }

  #resize {
    background: transparent;
    position: fixed;
    right: 30%;
    z-index: 999;
    top: 0;
    width: 10px;
    height: 100%;
    cursor: col-resize;
    background: #fff;
    text-align: center;
    &:hover {
      background: #dae1ff;
    }
  }
  #resize:after {
    display: inline-block;
    width: 0;
    height: 100%;
    vertical-align: middle;
    content: "";
  }

  #code-con {
    position: fixed;
    right: 0px;
    top: 50px;
    width: 30%;
    height: calc(100% - 50px);
    z-index: 999;
    background: #fff;
  }

  .code-show {
    margin-top: 0px;
    height: calc(100% - 112px);
  }
}
::v-deep .review-con pre,
::v-deep .kc-review pre {
  white-space: normal !important;
}
</style>
